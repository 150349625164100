import { graphql } from 'gatsby'
import React from 'react'
import HowItWorks from 'src/components/organisms/brandEducation/howItWorks'

type Props = {
  slice: any
}

const HostFundraiserSlice = ({ slice }: Props) => {
  const content: any = {
    anchor: slice.primary.anchor ?? undefined,
    showIcons: slice.primary.show_icons ?? true,
    description: slice.primary.description?.html ?? undefined,
    heading: slice.primary.heading?.text ?? undefined,
    listItems: slice.items.map((item: any) => ({
      headline: item.list_item_headline?.text ?? '',
      description: item.list_item_description?.html ?? '',
    })),
    primaryCtaText: slice.primary.primary_cta_text?.text ?? undefined,
    primaryLink: slice.primary.primary_link.url ?? undefined,
    primaryLinkTarget: slice.primary.primary_link?.target ?? undefined,
    secondaryWorksLinkText:
      slice.primary.secondary_works_link_text?.text ?? undefined,
    secondaryLink: slice.primary.secondary_link.url ?? undefined,
    secondaryLinkTarget: slice.primary.secondary_link?.target ?? undefined,
  }
  return <HowItWorks content={content} />
}

export default HostFundraiserSlice

export const howItWorksFragment = graphql`
  fragment PageDataBodyHowItWorks on PrismicPageDataBodyHowItWorks {
    items {
      list_item_headline {
        text
      }
      list_item_description {
        html
      }
    }
    primary {
      anchor
      show_icons
      description {
        html
      }
      heading {
        text
      }
      primary_cta_text {
        text
      }
      primary_link {
        url
        target
      }
      secondary_works_link_text: secondary_link_text {
        text
      }
      secondary_link {
        url
        target
      }
    }
  }
`
