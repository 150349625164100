import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React from 'react'
import BertholdTitle from 'src/components/atoms/bertholdTitle'

import { PrismicLinkType } from 'src/typings/generated/graphql'
import { Link } from 'gatsby'
import * as Styles from './basicSlideCard.module.scss'

type Props = {
  headline: string
  details: string
  image?: IGatsbyImageData
  alt?: string
  link?: PrismicLinkType
}

const BasicSlideCard = ({
  headline,
  details,
  image,
  alt = '',
  link,
}: Props) => (
  <div className={Styles.basicSlideCard}>
    {(() => {
      if (image && link?.url) {
        return (
          <Link to={link?.url || '#'}>
            <GatsbyImage className={Styles.image} image={image} alt={alt} />
          </Link>
        )
      }
      if (image && !link?.url) {
        return <GatsbyImage className={Styles.image} image={image} alt={alt} />
      }
      return null
    })()}
    <h3>{BertholdTitle({ text: headline })}</h3>
    <p>{details}</p>
  </div>
)

export default BasicSlideCard
