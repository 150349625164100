import React from 'react'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import BasicSlideCard from 'src/components/molecules/cards/basicSlideCard'
import DescriptiveCard from 'src/components/molecules/cards/descriptiveCard'
import { generateRandomId } from 'src/utils/domHelper'
import { PrismicLinkType } from 'src/typings/generated/graphql'
import * as Styles from './descriptiveSlider.module.scss'

export type DescriptiveSliderItem = {
  headline?: string
  details?: string
  image?: IGatsbyImageData
  imageAlt?: string
  link?: PrismicLinkType
}

enum DescriptiveSliderCopyLayout {
  standard = 'standard',
  overlay = 'overlay',
}

export type DescriptiveSliderContent = {
  anchor?: string
  copyLayout?: string
  id?: string
  items?: DescriptiveSliderItem[]
}

type Props = {
  content: DescriptiveSliderContent
}

const DescriptiveSlider = ({ content }: Props) => {
  const {
    anchor = generateRandomId(),
    copyLayout = DescriptiveSliderCopyLayout.standard,
    id = '',
    items = [],
  } = content

  const copyLayoutFormatted = copyLayout.toLowerCase()
  const slide = (item: DescriptiveSliderItem) => {
    if (copyLayoutFormatted === DescriptiveSliderCopyLayout.overlay) {
      return (
        <div className={Styles.descriptiveCardWrapper}>
          <DescriptiveCard
            title={item.headline ?? ''}
            description={item.details ?? ''}
            image={item.image}
            alt={item.imageAlt ?? ''}
          />
        </div>
      )
    }

    return (
      <BasicSlideCard
        headline={item.headline ?? ''}
        details={item.details ?? ''}
        image={item.image}
        alt={item.imageAlt ?? ''}
        link={item.link ?? undefined}
      />
    )
  }

  let sliderClass = Styles.descriptiveSlider

  if (copyLayoutFormatted === DescriptiveSliderCopyLayout.overlay) {
    sliderClass += ` ${Styles.overlay}`
  }

  return (
    <section id={anchor} className={sliderClass}>
      <div id={`slider-${id}`} className={Styles.slider}>
        {items.map((item, i: number) => {
          const key = `descriptive-slide-${i}`
          return (
            <div className={Styles.slideWrapper} key={key}>
              {slide(item)}
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default DescriptiveSlider
